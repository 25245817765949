
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import { defineComponent, onMounted, ref, toRefs, watch } from "vue";
import * as Yup from "yup";

export default defineComponent({
  emits: ["sendData", "sendDetailData"],
  name: "RoleForm",
  props: {
    roleToEdit: {
      type: Object,
      required: false,
    },
    typeForm: {
      required: true,
      type: String,
      default: "new",
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const RolFormModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formRoleData: any = ref({
      id: "",
      name: "",
      description: "",
      id_role_condition: 1,
      list_access: [],
    });
    const catModules: any = ref([]);
    const catRoleTypes: any = ref([]);
    let role: any = toRefs(props).roleToEdit;

    const retData = () => {
      emit("sendData");
    };

    const retDetailData = () => {
      emit("sendDetailData");
    };

    watch(role, (current) => {
      let idAcessArray: any = [];
      if (current.role_list_access !== undefined) {
        current.role_list_access.forEach((element) => {
          for (const key in element) {
            if (key === "idAccess") {
              idAcessArray.push({ id_access: element[key] });
            }
          }
        });
      }
      formRoleData.value = {
        id: current.id,
        name: current.name_rol,
        description: current.description_rol,
        id_role_condition: current.id_role_condition,
        name_role_condition: current.name_role_condition,
        list_access: idAcessArray,
      };
      //   console.log("formroledata", formRoleData.value.id_role_condition);
    });

    const validateRole = Yup.object().shape({
      role_name: Yup.string()
        .required("El nombre del rol es obligatorio")
        .label("Rol"),
    });

    const onInvalidSubmit = (values) => {
      console.log(values);
    };

    //Obtener catálogo de módulos con sus permisos
    const getPermissions = () => {
      ApiService.get("api/modules")
        .then((result) => {
          if (result.data.success) {
            let response = result.data.data.modules;

            for (let key in response) {
              let value = response[key];
              value.selectedModule = false;
              catModules.value.push(value);
            }
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    //Obtener catálogo de tipos de rol
    const getRoleTypes = () => {
      ApiService.get("api/role-condition")
        .then((result) => {
          if (result.data.success) {
            let response = result.data.data;
            for (let key in response) {
              let value = response[key];
              catRoleTypes.value.push(value);
            }
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    //Seleccionar todos los permisos del módulo
    const selectAllPermissions = (modulo) => {
      for (const key in modulo.list_access) {
        let idInArray = formRoleData.value.list_access.includes(
          modulo.list_access[key].idAccess
        );
        if (idInArray) {
          formRoleData.value.list_access.splice(
            formRoleData.value.list_access.indexOf(
              modulo.list_access[key].idAccess
            ),
            1
          );
        } else {
          formRoleData.value.list_access.push(modulo.list_access[key].idAccess);
        }
      }
    };

    /**Pendiente desarrollar funcionalidades para "seleccionar todos" */
    // const checkSelectedAll = (modulo) => {
    //   console.log("módulo", modulo);
    //   console.log("formRole", formRoleData.value);
    // };

    //Enviar datos del rol
    const submit = (values, { resetForm }) => {
      if (formRoleData.value.list_access.length > 0) {
        loading.value = true;

        if (!formRoleData.value.id) {
          ApiService.post("api/roles", formRoleData.value)
            .then(({ data }) => {
              if (data.success) {
                Swal.fire({
                  text: "¡Rol registrado con éxito!",
                  icon: "success",
                  allowOutsideClick: false,
                  buttonsStyling: false,
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  loading.value = false;
                  closeModal();
                  resetForm();
                  retData();
                });
              }
            })
            .catch(({ response }) => {
              if (response !== undefined) {
                let errors = "";
                console.log(response);
                for (let key in response.data.errors) {
                  let error = response.data.errors[key];
                  for (let keyError in error) {
                    let errorMsg = error[keyError];
                    errors += errorMsg + "</br>";
                  }
                }
                loading.value = false;
                Swal.fire({
                  title: 'Error',//`!${response.data.message}!`,
                  html: `${errors}`,
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then((errors) => {
                  return errors;
                });
              }
            });
        } else {
          ApiService.put(
            `api/roles/${formRoleData.value.id}`,
            formRoleData.value
          )
            .then(({ data }) => {
              console.log(data);

              if (data.success) {
                let responseRole = data.data;
                console.log(responseRole);

                Swal.fire({
                  text: "¡Rol actualizado con éxito!",
                  icon: "success",
                  allowOutsideClick: false,
                  buttonsStyling: false,
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  loading.value = false;
                  closeModal();
                  resetForm();
                  if (props.typeForm == "detailEdit") {
                    retDetailData();
                  }
                  retData();
                });
              }
            })
            .catch(({ response }) => {
              if (response !== undefined) {
                let errors = "";
                for (let key in response.data.errors) {
                  let error = response.data.errors[key];
                  for (let keyError in error) {
                    let errorMsg = error[keyError];
                    errors += errorMsg + "</br>";
                  }
                }
                loading.value = false;
                Swal.fire({
                  title: `!${response.data.message}!`,
                  html: `${errors}`,
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: "Aceptar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then((errors) => {
                  return errors;
                });
              }
            });
        }
      } else {
        Swal.fire({
          text: "Debes seleccionar al menos un permiso para el rol",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Agregar permisos",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    //Cerrar modal y limpiar el formulario
    const closeModal = () => {
      formRoleData.value = {
        id: "",
        name: "",
        description: "",
        id_role_condition: 1,
        name_role_condition: "",
        list_access: [],
      };
      for (let index = 0; index < catModules.value.length; index++) {
        catModules.value[index].selectedModule = false;
      }

      hideModal(RolFormModalRef.value);
    };

    onMounted(() => {
      getPermissions();
      getRoleTypes();
    });

    return {
      formRoleData,
      submit,
      formRef,
      loading,
      closeModal,
      RolFormModalRef,
      onInvalidSubmit,
      validateRole,
      catModules,
      catRoleTypes,
      selectAllPermissions,
    };
  },
});
